const CREATORS = [
    {
        occupation: 'Presidente',
        name: 'Romanim Dagostin',
        image: 'romanim_dagostin',
        description: 'Presidente do Sicoob Credisulca desde 1989, Sr. Romanim Dagostin é o responsável por colocar a cooperativa entre as maiores de Santa Catarina, contribuindo diretamente pelo fortalecimento do cooperativismo em toda região. Pelo projeto é responsável pela supervisão, contribuindo pelo terceiro ano consecutivo para que o concurso Foco no Cooperativismo vire realidade.'
    },
    {
        occupation: 'Consultora de Desenvolvimento organizacional',
        name: 'Eveline Marcon Francisco Dagostin',
        image: 'eveline_dagostin',
        description: 'Consultora Organizacional, Eveline atua há quase 17 anos na Credisulca. Graduada em Ciências Contábeis e com cinco MBAs voltados ao cooperativismo de crédito, ela é uma entusiasta de boas ideias e uma grande incentivadora do concurso Foco no Cooperativismo. No projeto, é responsável pela supervisão e consultoria. Na vida pessoal, Eveline aproveite seus momentos de lazer para curtir a natureza. Casada há 20 anos, é mãe de duas meninas, uma de 11 e outra de 19 e também de 4 cachorros, 3 gatos, 55 pássaros e 5 tartarugas.'
    },
    // {
    //     occupation: 'Analista de Comunicação e Marketing',
    //     name: 'Felipe José Ferreira',
    //     image: 'felipe_ferreira',
    //     description: 'Idealizador do Foco no Cooperativismo, iniciativa que nasceu da imensa paixão e pelo desejo de fomentar a cultura na região. Bacharel em Direito, advogado, mestrando da UFSC, palestrante, consultor, gestor de projetos, fotógrafo e videomaker, membro do Inspiration Photographers, colunista de assuntos jurídicos na IPhoto Channel e na EPICS. Palestrou em grandes palcos do cenário nacional. Escreveu "Fotografia, edição e diagramação de imagens" pela Editora UNOESC. Rabisca umas poesias, toca violão, pratica trekking e montanhismo, ama a natureza. É extremamente sonhador. Apaixonado por cinema. Tem um “ursinho” chamado Snow. Já foi atleta de Handebol e adora assar um churrasco, ama chimarrão! Papai da princesa Helena.'
    // },
    // {
    //     occupation: 'Assistente de desenvolvimento de software',
    //     name: 'Rodrigo Pasini de Souza',
    //     image: 'rodrigo_pasini',
    //     description: 'Responsável pelo design visual e desenvolvimento do site Foco no Cooperativismo. Bacharel em Eng. da Computação. Desenvolvedor Web e Mobile. Gestor do setor de Desenvolvimento de Software no Sicoob Credisulca com experiência em publicidade e propaganda. Vencedor do concurso Cidadata do Banco Central do Brasil. Criador do canal Death Awaits com mais de 7 milhões de visualizações. Já conheceu 2 maravilhas do mundo. Fã do Avicii e Alok, por isso adora a frase "Viva uma vida que você irá se lembrar".'
    // },
    // {
    //     occupation: 'Assistente de comunicação e marketing',
    //     name: 'Logans Daniel Rodrigues',
    //     image: 'logans_rodrigues',
    //     description: 'Assistente de Comunicação no setor de Marketing do Sicoob Credisulca, é acadêmico de Publicidade e Propaganda na Faculdade SATC. Dentro do concurso, é responsável pela identidade visual e criação das peças de divulgação em todas as plataformas digitais.'
    // }
]

export default CREATORS