import React, { Fragment } from "react"
import { Layout } from "@components/organisms"
import { BusinessDescription, ImageWithText, PageHeader } from "@components/molecules"
import { Countdown, ContestSteps } from "@pagesComponents/about"
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CREATORS from "@constants/creators"
import { globalStyles } from '@styles'


const About = () => (
    <Layout seo={{ title: 'Sobre o Concurso', description: 'Saiba mais sobre o concurso de fotografia do Sicoob Credisulca' }}> 
        <ImageWithText title='Sobre o Foco no Cooperativismo' image='winning2019.professional_2' reverse>
            O Foco no Cooperativismo é um concurso de caráter cultural que visa valorizar a arte da fotografia, fomentando a cultura e a responsabilidade
            socioambiental e sustentável e incentivando a participação da comunidade em ações colaborativas. Destacamos talentos, divulgamos belezas
            naturais, provocando o debate socioambiental e despertamos para a consciência sustentável. O resultado final é uma
            exposição itinerante com as obras vencedoras em todas as agências do Sicoob Credisulca.
        </ImageWithText>
        <Typography component='h1' sx={{ ...globalStyles.subtitle('h4'), mt: 17 }} color='primary' align="center" >
            Aguarde... Estamos preparando a edição 2023!
        </Typography>
        {/* <Countdown/> */}
        {/* <ContestSteps/> */}
        <PageHeader icon={{ name: 'collaboration', alt: 'Icone Colaboração' }} description='Conheça os responsáveis pela elaboração e execução do Concurso de
            Fotografia - Foco no Cooperativismo'
        >
            Quem somos
        </PageHeader>
        <BusinessDescription style={{ minHeight: '45vh', mb: 5 }}/>
        <Box sx={{ mt: 6 }}/>
        { CREATORS.map((creator, index) => (
            <Fragment key={ index }>
                <ImageWithText image={ `creators.${ creator.image }`} title={ creator.name } maxHeight='85vh' subtitle={ creator.occupation } reverse={ index % 2 === 0 }>
                    { creator.description }
                </ImageWithText>
                <Box sx={{ mt: { md: 8, xs: 3 } }}/>
            </Fragment>
        ))}
    </Layout>
)

export default About
